
.vertical-table-row--hide-title{
    margin-bottom: 20px;
    .row{
        >[class*="col"]:first-child{
            display: none;
        }
        >[class*="col"]{
            >*{
                padding: 0 !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .vertical-table-row--hide-title{
        margin-bottom: 40px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

