
.chat-list {
    &--group.v-list {
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 228px;
        min-height: 228px;
    }
    &.v-list-item {
        padding: 16px;
        border-bottom: 1px solid var(--v-grey-lighten3);
        display: block;
    }
}
.chat-button.v-btn.v-size--default {
    font-weight: 700;
    font-size: 14px !important;
    height: 48px !important;
    padding: 0 16px !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .chat-list {
        &--group.v-list {
            max-height: 300px;
            min-height: 300px;
        }
        &.v-list-item {
            padding: 24px;
            border-bottom: 1px solid var(--v-grey-lighten3);
            display: block;
        }
    }
    .chat-button.v-btn.v-size--default {
        font-size: 16px !important;
        height: 60px !important;
        padding: 0 24px !important;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
