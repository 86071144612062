
::v-deep {
    .v-btn{
        &::before{
            display: none;
        }
    }
    .v-btn.v-size--x-large:not(.v-btn--icon) {
        min-width: 0;
        padding: 0 10px;
        height: 50px !important;
    }
}
@media (min-width:1024px){
    .v-application .v-btn.grey--text.text--darken-4:hover {
        background-color: var(--v-primary-base) !important;
        color: #fff !important;
    }
}
