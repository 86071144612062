
.v-btn.v-size--small:not(.v-btn--icon).footer__link {
    padding: 0 10px;
    margin: 0 -10px;
}
::v-deep {
    .v-divider--vertical {
        align-self: center;
        vertical-align: unset;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
        background: transparent;
        input::placeholder {
            color: #fff !important;
        }
    }
}
.footer {
    &__family-site {
        &.v-text-field--outlined.v-input--dense {
            border-radius: 0;
        }
        &.white--text {
            border-color: var(--v-grey-base);
        }
    }
}
