
.products-item{
    --rounded: 8px;
    display: flex;
    flex-direction: column;
    &__contents{
        padding-top: 12px;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: calc(100% + 30px);
            background-color: var(--v-grey-lighten3);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .products-item{
        --rounded: 12px;
        &__contents{
            padding-top: 30px;
            &::before{
                height: calc(100% + 60px);
            }
        }
    }
    .products-items{
        >[class*="col"]:nth-child(2n){
            .products-item{
                flex-direction: column-reverse;
                &__contents{
                    padding-top: 0 !important;
                    padding-bottom: 30px;
                    &::before{
                        bottom: unset;
                        top: 0;
                    }
                }
            }
        }
    }
}
@media (min-width:1024px){
    .products-item{
        &__contents{
            padding-top: 40px;
            &::before{
                height: calc(100% + 80px);
            }
        }
    }
    .products-items{
        >[class*="col"]:nth-child(2n){
            .products-item{
                &__contents{
                    padding-bottom: 40px;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

