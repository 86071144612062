
.tit--dot{
    .tit{
        width: fit-content;
        padding-right: 22px;
        position: relative;
        line-height: 1;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--v-grey-lighten1);
            top: 0;
            right: 0;
        }
    }
    &.align-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    &--lg{
        .tit{
            &::after{
                background-color: var(--v-primary-base);
            }
        }
    }
}
.white--text{
    .tit--dot{
        .tit{
            &::after{
                background-color: #fff;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit--dot{
        &--lg{
            .tit{
                padding-right: 26px;
                &::after{
                    width: 16px;
                    height: 16px;
                    background-color: var(--v-primary-base);
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

