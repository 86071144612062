
.main-media{
    --rounded: 8px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-media{
        --rounded: 12px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

