
.header-quick{
    &__text{
        font-size: 12px;
        line-height: 1;
        color: var(--v-grey-lighten1);
        text-align: center;
        margin-top: 4px;
    }    
    &__btn{
        &::before{
            display: none;
        }        
    }
}
::v-deep{
    .v-badge--dot .v-badge__badge{
        transform: translate(2px, -2px);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-application .v-btn.grey--text.text--darken-4:hover {
        background-color: var(--v-primary-base) !important;
        color: #fff !important;
    }
}
@media (min-width:1200px){
}
