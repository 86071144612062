
.inquiary-card {
    --rounded: 8px;
    --input-height: 50px;
    ::v-deep {
        .v-text-field {
            padding: 0;
            margin: 0;
        }
        .v-text-field__slot {
            min-height: var(--input-height) !important;
            display: flex;
            align-items: flex-end;
            .v-label {
                top: 0;
                transform: translateY(0) scale(1);
                font-size: 12px;
            }
        }
        .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
            border-color: var(--v-grey-darken4);
        }
    }
}
.v-application--is-ltr .v-btn-toggle>.v-btn.v-btn:not(:first-child), .v-application--is-rtl .v-btn-toggle>.v-btn.v-btn:not(:last-child) {
    border-left-width: 1px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .inquiary-card {
        --rounded: 12px;
        --input-height: 56px;
        ::v-deep {
            .v-text-field__slot {
                .v-label {
                    font-size: 14px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
