
.main-section {
    &--products {
        background-image: url(/images/ci-light.svg);
        background-position: right top;
        background-size: 50% auto;
    }
    &--media {
        position: relative;
        &__bg {
            width: 100%;
            height: calc(100% - var(--main-section-padding-y-xs) - (var(--tit-wrap-margin-xxl) / 2) - var(--tit-font-size-xl));
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url(/images/main/media-bg.jpg);
            background-position: center;
            background-size: cover;
            z-index: -1;
        }
    }
    &--inquiary {
        background-color: var(--v-grey-lighten5);
        &__right {
            width: 100%;
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100vw;
                height: calc(100% + var(--main-section-padding-y-sm) + 20px);
                bottom: calc(var(--main-section-padding-y-sm) * -1);
                left: 50%;
                transform: translateX(-50%);
                background-color: var(--v-primary-base);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section {
        &--media {
            &__bg {
                height: calc(100% - var(--main-section-padding-y-xs) - var(--tit-wrap-margin-xxl) - var(--tit-font-size-xl));
            }
        }
        &--inquiary {
            background-image: url(/images/main/inquiary-bg.jpg);
            &__right {
                &::before {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-section {
        &--media {
            &__bg {
                height: calc(100% - var(--main-section-padding-y-xs) - var(--tit-wrap-margin-xxl) - 120px);
            }
        }
    }
}
@media (min-width: 1200px) {
}
