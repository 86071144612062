
.chat-list{
    &--group.v-list{
        padding: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 204px;
        min-height: 204px;
    }
    &.v-list-item{
        margin: -4px 0;
        padding: 0;
        min-height: 0;
        &.chat-list--left{
            justify-content: flex-start;
            padding-right: 20px;
        }
        &.chat-list--right{
            justify-content: flex-end;
            padding-left: 20px;
            text-align: right;
        }
    }
    &__card{
        margin: 4px 0;
        max-width: 100%;
    }
    &__replay{
        max-width: calc(100% - 32px)
    }   
    &__loading{
        min-width: 80px;
        margin-top: 10px;
    }
}
.v-card__actions{
    --input-height: 50px;
    padding: 12px;
    ::v-deep{
        .v-text-field--rounded>.v-input__control>.v-input__slot {
            padding-left: 16px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .chat-list{
        &--group.v-list{
            padding: 24px;
            max-height: 288px;
            min-height: 288px;
        }
        &.v-list-item{
            margin: -8px 0;
        }        
        &__card{
            margin: 8px 0;
            max-width: 100%;
        }
    }
    .v-card__actions{
        --input-height: 56px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
