
::v-deep {
    input {
        text-align: center;
    }

    .v-phone-field-rules {
        .v-input__slot {
            display: none;
        }
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
