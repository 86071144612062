
.chat-area {
    min-width: calc(100vw - 14px) !important;
    max-width: calc(100vw - 14px);
    padding: 0 66px 0 0;
    &__inner {
        width: 100%;
        height: 90vh;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: transparent !important;
    }
    .v-tabs-items,
    .v-window-item {
        width: 100%;
        height: 100%;
    }
    .v-window-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 18px 0 0 0;
    }
    ::v-deep {
        .chat-area__card {
            --card-border-radius: 12px;
            width: 100%;
            overflow: hidden;
            background-color: transparent !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .chat-area {
        min-width: 476px !important;
        max-width: 476px;
        padding: 0 76px 56px 0;
        ::v-deep {
            .chat-area__card {
                --card-border-radius: 16px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
