
.kakao-btn {
    z-index: 10;
    background-image: url(/images/icon/icon-kakao-chat.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
        bottom: 76px !important;
    }
}
.chat-btn {
    z-index: 10;
    background-image: url(/images/icon/icon-chat.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.v-btn {
        overflow: visible;
        &::before {
            display: none;
        }
    }
    &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
        bottom: 76px !important;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 26px;
        height: 24px;
        background-image: url(/images/icon/icon-chat-ani.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        animation: chatAni 1.2s infinite ease-out;
    }
    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
@keyframes chatAni {
    0% {
        top: -2px;
    }
    50% {
        top: 2px;
    }
    100% {
        top: -2px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .kakao-btn {
        &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
            bottom: 84px !important;
        }
    }
    .chat-btn {
        &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
            bottom: 84px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.fixed-contents {
    // overflow: hidden;
    &__fixed-con {
        display: none;
        height: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .fixed-section {
        position: relative;
        display: block;
        &.active {
            .fixed-contents {
                &__fixed {
                    position: fixed;
                    top: 12px;
                    & + .fixed-contents__fixed-con {
                        display: block;
                    }
                }
            }
        }
        &.bottom {
            .fixed-contents {
                &__fixed {
                    position: absolute !important;
                    top: auto !important;
                    left: auto !important;
                }
                &__fixed-con {
                    display: block;
                }
            }
        }
    }
    .fixed-contents {
        position: relative;
        &__fixed,
        &__fixed-con {
            width: 290px;
        }
    }
}
@media (min-width: 1200px) {
}
